.admission {
	border-radius: 10px;
	max-width: 300px;        /* Limite la largeur à 300px */
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex: 0 0 auto;         /* Empêche la carte de grandir ou rétrécir */
}



.admissions > .container {
	display: flex;
	flex-wrap: nowrap;
	gap: 20px;
	margin-top: 10px;
	width: 100%;
	overflow-x: scroll;
}
