.modal {
	position: absolute;
	z-index: 1;
	right: 0px;
	top: 25px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: var(--background);
}

.modal-content {
	display: flex;
	gap: 10px;
}

.modal .row2:hover > * {
	color: var(--orange) !important;
}

.modal .row2 {
	width: 100%;
}

.modal .row2 > div > div {
	height: fit-content;
	display: flex;
	align-items: center;
}
