.params-field {
	padding: 20px 0;
	border-left: 0;
	border-top: 0;
	border-right: 0;
	border-radius: 0;
}

.params-field h3 {
	margin-bottom: 10px;
}

.params-field input {
	width: 100%;
}

.params-field button[type="submit"] {
	background-color: var(--orange);
	color: white;
}

.error-message {
	color: #df1818;
	margin-top: 7px;
}

.params-field .field label {
	display: block;
	margin-bottom: 10px;
}
