#section2 {
	width: 95%;
	margin: auto;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

#section2 h1 {
	text-align: center;
}

#section2 > img {
	width: 500px;
	margin: auto;
	margin-top: 30px;
}

.landing p,
.landing li {
	font-family: Inter-Medium;
	font-size: clamp(1rem, 2.2vw + 0.1rem, 1.2rem);
}

#section2 p {
	text-align: center;
}

.mockups-container {
	width: 70%;
	margin: auto;
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.mockups-container img {
	width: clamp(250px, 45%, 500px);
}

.mockups-container > div {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	text-align: center;
}

.mockups-container > div:nth-child(1) {
	left: 0;
	top: 0;
}

.mockups-container > div:nth-child(2) {
	right: -50px;
	top: 0;
}

.mockups-container > div:nth-child(4) {
	left: 0;
	bottom: 0;
}

.mockups-container > div:nth-child(5) {
	right: 20px;
	bottom: 0;
}

.mockups-container > div img {
	width: 50px;
}

.landing h4,
.landing h4 span {
	font-size: clamp(1rem, 2.5vw + 0.1rem, 1.5rem);

	font-family: "Inter-SemiBold";
}

.landing span {
	color: var(--orange);
}

@media (max-width: 905px) {
	#section2 {
		position: relative;
	}

	#section2 .mockups-container {
		position: static;
		display: flex;
		width: fit-content;
		flex-direction: column;
		gap: 50px;
		padding: 20px;
		margin-top: 20px;
	}

	#section2 #mockup-2 {
		width: 500px;
		position: absolute;
		top: 50%;
		right: -40%;
		opacity: 0.5 !important;
		transform: translateY(-50%) !important;
		z-index: -1;
	}

	.mockups-container > div {
		position: initial;
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 10px;
		padding: 10px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
	}
}

@media (max-width: 450px) {
	#section5 .grid {
		grid-template-columns: 1fr !important;
		gap: 40px !important;
	}

	#section5 .grid .card * {
		text-align: center;
	}
}
