.crop-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1111111111;
}

.btn-container {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1111;
	display: flex;
	flex-direction: column;
	gap: 2px;
	border-radius: 10px;
	background-color: var(--orange);
}

.btn-container button {
	background-color: var(--light-blue);
	padding: 20px 10px;
	color: var(--font-color);
}

.btn-container button:first-child {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-container button:last-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
