.info {
	position: relative;
	display: flex!important;
	align-items: center;
	justify-content: center;
	font-size: 17px !important;
	padding: 15px;
	color: var(--font-color) !important;
}

.info-bubble {
	display: none;
	background-color: var(--background);
}

.info:hover .info-bubble {
	width: max-content;
	max-width: 400px;
	display: block;
	position: absolute;
	right: calc(100%);
	top: 20px;
	padding: 15px;
	z-index: 1;
	border-radius: 10px;
	box-shadow:
		rgba(17, 17, 26, 0.1) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 5px;
	background-color: var(--background);
}

.info.hover {
	position: relative;
	display: inline-flex; /* Utilisez inline-flex pour garder le span sur la même ligne que le texte */
	align-items: center; /* Centre verticalement le contenu du span */
	justify-content: center; /* Centre horizontalement le contenu du span */
	width: 20px; /* Spécifiez une largeur fixe pour la cohérence */
	height: 20px; /* Spécifiez une hauteur fixe pour la cohérence */
	border-radius: 50%; /* Pour rendre le fond du span circulaire, si nécessaire */
	font-size: smaller; /* Ajustez la taille de la police à l'intérieur du span si nécessaire */
}
