.loadingpage {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background: var(--background);
	z-index: 1000000000;
	text-align: center;
	flex-direction: column;
	gap: 10px;
}

.loadingpage img {
	width: 500px;
	filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
}

@media (max-width: 905px) {
	.loadingpage img {
		width: 300px;
	}
}
