.similars {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 200px;
	position: relative;
	min-height: 200px;
}

.similars > div > div {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
}
