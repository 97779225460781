.metric-hidde {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	backdrop-filter: blur(4px); /* Applique un effet de flou */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 1; /* Assurez-vous que cet élément est au-dessus des contenus masqués */
}

.metric-hidde.noData{
	backdrop-filter: blur(1.5px) !important; /* Applique un effet de flou */
}

.metric-hidde h2,
.metric-hidde p {
	font-family: "Inter-SemiBold", sans-serif;
}
.metric-hidde > p {
	padding-bottom: 50px;
}
.metric-hidde div {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	background: rgba(255, 255, 255, 0.95);
	border: 1px solid var(--orange);
	border-radius: 0 0 10px 10px;
	position: absolute;
	bottom: 0;
	padding: 50px;
	align-items: center;
}
.metric-hidde.noData.top div {
	bottom: auto;
	top: 0;
	border-radius: 10px 10px 0px 0px;
}

.metric-hidde a {
	background: var(--orange);
	color: white;
	border-radius: 10px;
	width: fit-content;
	padding: 10px 20px;
	text-decoration: none;
}

.metric-hidde.avis div {
	bottom: 25%;
}

.metric-hidde.avis > p {
	padding-bottom: 200px;
}
