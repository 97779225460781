.hide #headerico {
	display: none !important;
}

.static .fixed-bar {
	display: none !important;
}

@media (max-width: 1320px) {
	.hide .toggle-switch,
	.hide #adminsearch {
		display: none !important;
	}
}

.hide .static .fixed-bar {
	display: flex !important;
	gap: 20px;
	justify-content: start;
	transform: scale(0.8) translateX(-80px);
	flex-wrap: nowrap !important;
}

.static {
	width: 100%;
	background-color: var(--background);
	border-bottom: 1px solid var(--light-blue);
	user-select: none;
}

.static {
	position: fixed;
	display: flex;
	flex-direction: row;
	gap: 25px;
	top: 0;
	margin-left: auto;
	height: 65px;
	z-index: 11;
}

.static h4 {
	margin: auto 0;
	color: var(--orange);
}

.static #toggle-nav span:not(.fixed-bar span) {
	cursor: pointer;
	padding: 10px;
	border-radius: 100%;
}

.toggle-switch {
	margin: auto 0;
}

.static #headerico {
	width: 230px;
	margin: auto 0;
	height: auto;
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}

.static {
	transition: all 0.3s;
	justify-content: space-between;
	align-items: center;
	padding-right: 25px;
}

.static > div {
	display: flex;
	flex-direction: row;
	gap: 50px;
}

.static > div img {
	height: 50px;
	object-fit: cover;
	width: 50px;
	border-radius: 50px;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
}

@media (max-width: 620px) {
	#toggle-nav {
		margin-left: auto;
	}

	.static {
		height: fit-content;
		z-index: 100000000;
	}

	.static *:not(:first-child) {
		display: none;
	}

	.static #toggle-nav svg {
		display: block;
	}
}

.admin-indicator {
	width: 100%;
	position: absolute;
	bottom: 0;
	background-color: var(--orange);
	z-index: 1;
	border-radius: 0 0 45px 45px;
	font-size: 10px;
	text-align: center;
	color: white;
	padding: 5px;
	padding-bottom: 10px;
	font-family: "Inter-Bold", sans-serif;
}

#logout {
	font-size: 20px;
	opacity: 0.8;
}

.static #toggle-nav span:not(.fixed-bar span):hover {
	background: rgba(241, 152, 41, 0.62);
	scale: 1.05;
}
