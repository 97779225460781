.toggle-switch {
	position: relative;
	width: 100px;
	height: 30px;
	--light: #ffffff;
	--dark: #161616;
	--link: rgb(27, 129, 112);
	--link-hover: rgb(24, 94, 82);
}

.landing header .toggle-switch {
	width: 80px;
}

.switch-label {
	position: absolute;
	width: 100%;
	height: 30px;
	background-color: var(--dark);
	border-radius: 25px;
	cursor: pointer;
	border: 3px solid var(--dark);
}

.home .toggle-switch,
.home .switch-label {
	width: 80px !important;
}

.checkbox {
	position: absolute;
	display: none !important;
}

.slider {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 25px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.checkbox:checked ~ .slider {
	background-color: var(--light);
}

.slider::before {
	content: "";
	position: absolute;
	top: 3px;
	left: 10px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	-webkit-box-shadow: inset 12px -4px 0px 0px var(--light);
	box-shadow: inset 6px -3px 0px 0px var(--light);
	background-color: var(--dark);
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.checkbox:checked ~ .slider::before {
	-webkit-transform: translateX(40px);
	-ms-transform: translateX(40px);
	transform: translateX(40px);
	background-color: var(--orange);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.floating-menu .toggle-switch {
	width: 60px;
}

.floating-menu .switch-label {
	height: 25px !important;
}

.floating-menu .slider::before {
	top: 2px;
	left: 7px;
	width: 15px;
	height: 15px;
}

.floating-menu .checkbox:checked ~ .slider::before {
	-webkit-transform: translateX(30px) !important;
	-ms-transform: translateX(30px);
	transform: translateX(30px);
}
