.row-text {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.row-text p:first-child {
	font-family: "Inter-Bold";
	flex-grow: 0;
	flex-shrink: 0;
	white-space: nowrap; /* Empêche le texte de passer à la ligne */
	overflow: hidden; /* Masque le contenu débordant le conteneur */
	text-overflow: ellipsis; /* Ajoute des points de suspension si le texte est tronqué */
}

.row-text p:last-child {
	flex-grow: 1; /* Permet au second paragraphe de prendre l'espace restant */
}
