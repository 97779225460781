.filter {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 217px;
	background-color: var(--background);
}

.filter.active > div {
	border-color: var(--orange) !important;
	border-width: 2px;
}

.open,
.filter.open .modal {
	border-color: var(--border-hover-focus-light);
	background-color: var(--background);
}

.dark_mode .open,
.dark_mode .filter.open .modal {
	border-color: var(--border-hover-focus-dark);
}

.filter.open {
	border-radius: 10px 10px 0 0;
	z-index: 1;
	-webkit-transform: translate3d(0, 0, 0);
}

.filter label {
	cursor: pointer;
	color: var(--font-color) !important;
}

.filter.open svg {
	transform: rotate(180deg);
}

.filter h4 {
	font-family: "Inter-SemiBold", sans-serif;
}

.filter .row {
	background-color: var(--background);
}

.filter .row.open {
	border-radius: 10px 10px 0 0;
	border-bottom: 1px solid transparent !important;
}

.filter .row span {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #505050;
	color: white;
	width: 25px;
	position: relative;
	height: 25px;
	border-radius: 20px;
	font-family: "Inter-Bold", sans-serif;
}

.filter .row span.changes {
	background-color: var(--orange);
}

.filter .search-container {
	position: relative;
}

.filter .search-container input {
	width: 100%;
	padding-left: 30px;
}

.filter .search-container svg {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.filter .modal {
	gap: 20px;
	position: absolute;
	left: 0;
	min-width: 300px;
	max-height: 353px;
	max-width: 357px;
	top: 0;
	margin-top: 45.5px;
	z-index: -1;

	border-radius: 0 20px 20px 20px;
}

.filter button {
	width: 120px;
	font-size: 1rem;
}

.filter button:last-child {
	background-color: #505050;
	color: white;
}

.rc-slider-rail {
	background-color: #d9d9d9;
}

.rc-slider-track {
	background-color: #3784eb;
}

.rc-slider-handle {
	border-color: #3784eb;
}

.rc-slider-handle:focus,
.rc-slider-handle:hover,
.rc-slider-handle:active {
	border-color: #3784eb;
	box-shadow: 0 0 0 5px rgba(55, 132, 235, 0.2);
}

/* Cacher les flèches des inputs type number dans Chrome, Safari, Edge, Opera */
.filter input[type="number"]::-webkit-outer-spin-button,
.filter input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Cacher les flèches des inputs type number dans Firefox */
.filter input[type="number"] {
	-moz-appearance: textfield;
}
