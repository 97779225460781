#section1 {
	flex-wrap: wrap;
	height: 91svh;
	width: 85%;
	min-height: fit-content;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.landing a {
	text-decoration: none;
}

.landing h2,
.landing h2 span {
	font-family: Inter-Bold;
	font-size: 30px;
}

.landing-btn {
	border-radius: 10px;
	padding: 10px 20px;
	font-family: Inter-Bold, serif;
}

.register {
	background-color: var(--orange);
	color: white;
}

#login {
	color: var(--orange);
	border: 1px solid var(--orange);
}

@media (max-width: 905px) {
	#section1 {
		gap: 10px;
		justify-content: center;
	}

	#section1 h1 {
		margin-top: 0;
	}

	#section1 > img {
		display: none;
	}
}
