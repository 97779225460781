.selector {
	width: 550px;
	padding: 0;
	padding-right: 50px;
	border-radius: 10px;
	cursor: pointer;
	position: relative;
	display: inline-block;
}

.selector.structureSelector {
	width: 300px;
}
.selector-color .selector.selected {
	background-color: var(--orange);
	color: white;
}

.selector .chevron {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.selector.active {
	border-color: var(--orange);
}

.selector.active .chevron {
	transform: translateY(-50%) rotate(180deg);
}

.selector-list {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--background);
	max-height: 220px;
	z-index: 1;
	left: 0;
	overflow-y: auto;
	padding: 0;
}

.selector-item,
.selector-label {
	display: flex;
	align-items: center;
	width: 100%;
	flex-shrink: 0;
	gap: 10px;
	padding: 8px;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	font-size: 14px;
	text-overflow: ellipsis; /* Add this line */
}

.selector-item {
	padding: 20px;
}

.selector-item:hover {
	background-color: rgba(236, 140, 39, 0.2);
}

.selector-label .text {
	flex-grow: 1;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.selector-item .text {
	flex-grow: 1;
	overflow: hidden;
	font-family: "Inter-Medium";
	text-overflow: ellipsis;
	font-size: 14px;
	white-space: nowrap;
}

.fixed-bar .selector-list {
	top: 101%;
}
