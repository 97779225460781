.client-form {
	width: 100%;
	max-width: 1500px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 20px;
	margin-bottom: 60px;
}

.client-form .field {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding-bottom: 20px;
}

.client-form .error {
	bottom: 0px;
}

.client-form button {
	width: 100px;
	margin: auto;
}
