.publication:not(.carousel .publication) {
	width: 320px;
}
.publication .img-container {
	width: 320px;
	height: 320px;
	position: relative;
	transition: 0.2s;
	cursor: pointer;
	padding: 0;
}

.publication {
	padding: 0 !important;
}

.publication .img-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px 10px 0 0;
}

.publication .row > .row2 {
	position: relative;
	padding: 15px;
	gap: 5px;
	width: max-content;
	opacity: 0.7;
}

.publication .row2 span {
	font-size: 0.9rem;
}

.publication .row {
	background-color: var(--background);
	position: relative;
	gap: 0 0px;
	padding: 0;
	border-bottom: 0;
	border-left: 0;
	border-right: 0;
	border-radius: 0 0 10px 10px;
}

.row > div > div {
	display: flex;
	align-items: center;
}

.publication .row2 span {
	font-size: 0.9rem;
}

.publication .modal {
	top: -30px;
	right: 10px;
}
