.moderation {
	min-width: 100px;
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: fit-content;
	padding: 0;
	justify-content: space-between;
}

.moderation button:not(.cancel) {
	width: 100%;
	padding: 8px 15px;
	border-top: 0;
	border-radius: 0;
	border-bottom: 0;
}

.moderation .cancel {
	width: 100%;
}

.moderation:not(.verified) button:not(.cancel):hover {
	transform: scale(1.1);
	z-index: 1;
}

.moderation:not(.verified) button:not(.cancel):last-child {
	border-right: 0;
	border-radius: 0 10px 10px 0;
}

.moderation:not(.verified) button:first-child:not(.cancel) {
	border-left: 0;
	border-radius: 10px 0 0 10px;
}

.moderation:not(.verified) button:last-child:not(.cancel):hover {
	background-color: #f90505;
}

.moderation button:hover svg {
	fill: white !important;
}

.cancel.verified {
	border: 2px solid #39b64d;
}

.cancel.refused {
	border: 2px solid #f90505;
}

.moderation:not(.verified) button:first-child:not(.cancel):hover {
	background-color: #39b64d;
}

.moderation.verified:hover{
	background-color: #f90505;
}