.grid-container {
	width: 100%;
	display: flex;
	gap: 10px;
	margin-top: 20px;
	flex-wrap: wrap;
}

.grid-container .line-chart {
	width: 100% !important;
	max-height: 550px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pie-chart {
	width: 100%;
	min-height: 300px;
	padding-top: 20px;
}

.pie-chart > div {
	width: 100%;
}

.pie-chart p {
	width: fit-content;
	margin: 30px 0 10px auto;
	font-family: "Inter-Bold", sans-serif;
	color: var(--orange);
	cursor: pointer;
}

.onTemp {
	margin-top: 20px;
}

.onTemp ion-icon {
	font-size: 40px;
}

.pie-chart ion-icon {
	color: var(--orange);
}

.line-chart {
	flex: 0.75;
}

.column {
	flex: 0.25;
}

.pie-chart,
.line-chart,
.info-div {
	width: 100%;
	text-align: center;
}

.locked-graph {
	background-color: rgba(238, 227, 206, 0.2);
}

.rank-container.locked-graph {
	border-radius: 10px;
	border: 1px solid var(--border-color-light);
}

.dark_mode .rank-container.locked-graph {
	border: 1px solid var(--border-color-dark);
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.grid-container {
		display: flex;
		flex-direction: column;
	}

	#visits-chart,
	#localisation-info {
		grid-column: span 1;
		grid-row: auto;
	}

	.grid-container .row2 {
		flex-direction: column;
	}

	.line-chart {
		height: 400px !important;
		flex: 1;
	}

	.grid-container .column {
		max-height: 500px;
		overflow: scroll;
	}
}

.publi-stats {
	width: 150px;
	height: 150px;
	object-fit: cover;
	padding: 0;
}

.publi-stats.selected {
	border: 4px solid var(--orange) !important;
}

.strength-container table {
	width: fit-content;
	padding-right: 10px;
	display: block;
	min-height: 500px;
	max-height: 700px;
	overflow: scroll;
	position: relative;
	border-right: 10px;
}

.strength-container .one-row table {
	min-height: fit-content !important;
}

.strength-container table thead th {
	background-color: var(--background);
	position: sticky;
	top: 0;
}

.strength-container table * {
	border-left: 0 !important;
	border-right: 0 !important;
	border-top: 0 !important;
}

.strength-container table tbody tr td:first-child {
	color: #3784eb !important;
	text-decoration: underline;
}
