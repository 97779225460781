.linkrow input {
	display: block;
	width: 300px;
	border-radius: 15px;
	font-size: var(--little-font);

	resize: none;
}

.linkrow {
	align-items: center;
}
