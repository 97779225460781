form.question .field,
.edit-form .field {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding-bottom: 20px;
}

form.question .field .error,
.edit-form .field .error {
	bottom: 0px;
}
