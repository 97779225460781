.databubble {
	margin: 0 auto;
	scale: 1.5;
	display: flex;
	justify-content: center;
	align-items: center;
}

.databubble h3 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50px;
	color: var(--font-color);
	box-shadow:
		rgba(17, 17, 26, 0.1) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 5px;
	margin-bottom: 10px;
}
