.datepicker input {
	width: 100px;
	margin: 0 !important;
	position: relative;
}

.dashboard {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	padding: 40px 30px;
}

.dashboard {
	max-width: 2100px;
	overflow: hidden;
}

.dashboard .column {
	width: fit-content;
	max-width: 280px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	flex: 1;
}

/* Ajout d'une media query pour changer l'orientation en row sous une certaine largeur */
@media (max-width: 1008px) {
	/* Ajustez 768px selon votre besoin de point de rupture */
	.dashboard .column {
		gap: 10px 40px;
		max-width: none;
		flex-direction: row;
		align-items: center; /* Ajouté pour aligner les éléments verticalement */
		flex-wrap: wrap; /* Permet aux éléments de s'enrouler s'ils ne rentrent pas sur une seule ligne */
	}
}

.dashboard .row2 {
	user-select: none;
	cursor: pointer;
	width: fit-content;
	padding-bottom: 2px;
	border-bottom: 2px solid transparent;
}

.dashboard .selected * {
	color: var(--orange);
}

.dashboard .selected {
	border-color: var(--orange);
}
