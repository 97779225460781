.sidebar a span > span {
	opacity: 0;
	visibility: hidden;
	display: none;
}

@keyframes identifier {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.active > span {
	background-color: var(--orange) !important;
}

.sidebar {
	user-select: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 72px;
	height: 100vh;
	padding: 40px 10px 30px 10px;
	background-color: var(--background);
	transition: 0.3s;
	z-index: 8;
	padding-top: 80px;
	border-right: 1px solid var(--light-blue);
}

.sidebar-header {
	width: 100%;
	margin-bottom: 44px;
}

.logo-icon {
	display: none;
	font-size: 28px;
	height: 35px;
	width: 51px;
	text-align: center;
}

.sidebar a {
	min-width: 51px;
	border: 0;
	padding: 0;
	text-decoration: none;
	cursor: pointer;
}

.sidebar .selected > span {
	background-color: var(--orange);
}

.sidebar a > span {
	display: flex;
	align-items: center;
	gap: 15px;
	height: 48px;
	padding: 0 12px;
	border-radius: 24px;
	line-height: 1;
	font-size: var(--little-font);
	font-family: "Inter-Bold";
}

.sidebar #pdp {
	padding: 0;
	padding-right: 12px;
}

.sidebar a > span > span {
	transition: 0.3s;
}

.sidebar a > span {
	min-width: 51px;
	width: 100%;
}

.sidebar img {
	width: 48px;
	height: 48px;
}

.sidebar a ion-icon {
	min-width: 28px;
	position: relative;
	font-size: 28px;
	transition: 0.2s;
}

.sidebar a span {
	color: var(--text-color);
	font-size: 0.9rem;
}

.sidebar > nav {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 30px;
}

.sidebar > nav div:last-child {
	margin-top: auto;
}

.sidebar span i,
.admin_menu i {
	display: grid;
	place-items: center;
	height: 20px;
	width: 20px;

	padding: 0 4px;
	border-radius: 30px;
	position: absolute;
	top: -0px;
	right: -0px;
	border: 1px solid #ffffff;
	background: #ff2f40;
	color: #f9f9f9;
	font-size: 12px;
	font-family: "Inter-Bold";
	font-style: normal;
}

@media (min-width: 620px) {
	.sidebar.active {
		width: 240px;
	}

	.sidebar.active a > span > span {
		white-space: nowrap;

		visibility: visible;
		display: block;

		animation: identifier 0.3s forwards;
	}

	.sidebar a:hover > span {
		background: rgba(241, 152, 41, 0.62);
	}

	.sidebar a:hover > span :is(ion-icon, img) {
		scale: 1.05;
	}
}

@media (max-width: 620px) {
	.sidebar {
		position: fixed !important;
		background-color: var(--background);
		z-index: 10000;
	}

	.static {
		padding-right: 12px;
	}

	.sidebar.active {
		top: 0;
		right: 0;
		border-left: 1px solid var(--light-blue);
	}

	.sidebar {
		right: -80px;
	}

	.sidebar span i {
		top: -0px;
		left: -0px;
	}
}

.pdpNew {
	width: 100px;
	height: 100px;
	border-radius: 70px;
	margin: auto;
	object-fit: cover;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
}

.inputsocial.pdpPopUp {
	margin-top: 0;
}

.sidebar span {
	position: relative;
}

.pdpPop .intitule > div {
	display: flex;
	gap: 10px;
}
