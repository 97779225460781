#adminsearch {
	width: clamp(250px, 100%, 500px);
	z-index: 100;
	position: relative;
}

#adminsearch .schoolscontainer {
	width: 100%;
	margin-top: 2px;
	position: absolute;
	left: 0;
	padding: 10px;
	background-color: var(--background);
	top: 100%;
	z-index: 1;
}

.schoolscontainer div {
	padding: 10px;
	border-bottom: 1px solid var(--font-color);
	cursor: pointer;
}

.schoolscontainer div:hover {
	background-color: var(--orange);
	color: var(--background);
}

#adminsearch svg {
	position: absolute;
	right: 25px;
	top: 50%;
	transform: translateY(-50%);
}

#adminsearch input {
	width: 100%;

	height: fit-content;
}

input[type="search"]::-webkit-search-cancel-button {
	position: relative;
	-webkit-appearance: none;
	height: 10px;
	width: 10px;
	/* 	position: absolute;
        right: 0; */
	background-color: var(--orange);
	border-radius: 50%;
	right: 30px;
}
