.input-wrapper {
	position: relative;
	margin-bottom: 10px;
}

.input-wrapper .error {
	position: relative;
}

.input-label {
	z-index: 1;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: start;
	position: absolute;
	left: 15px;
	top: 10px;
	font-size: 0.9rem;
	background-color: var(--background) !important;
	color: #7d7d7d;
	transition: 0.2s ease all;
	pointer-events: none;
}

.input-label.date {
	padding-right: 10px;
	padding-left: 10px;
	left: 5px;
}

.input-label .error {
	position: relative;
	font-size: 1rem;
	padding: 0;
	margin: 0;
}
.focused .input-label {
	top: -10px;
	left: 10px;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 12px;
	color: #000;
}

.input-field {
	width: 100%;
	outline: none;
	resize: none;
}
