.structure .pdp {
	transition: 0.2s;
	width: 100px;
	object-fit: cover;
	cursor: pointer;
	height: 100px;
	border-radius: 80px;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
	border: 2px solid transparent;
}

.structure .pdp.editable:hover {
	transform: scale(1.1);
	border-color: var(--orange);
}

.structure textarea {
	display: block;
	width: 500px;
	border-radius: 15px;
	font-size: var(--little-font);
	margin-top: 10px;
	resize: none;
}
