.params-selector {
	width: fit-content;
	display: flex;
	flex-direction: column;
	height: calc(100vh - 110px);
	border-left: 0;
	border-top: 0;
	border-bottom: 0;
	border-radius: 0;
	padding-right: 20px;
	gap: 10px;
}

.params-selector a {
	width: 100%;
	flex-shrink: 0;
	text-decoration: none;
	padding: 20px 15px;
	border-radius: 30px;
}

.params-selector a:hover {
	background: rgba(241, 152, 41, 0.62);
}

.params-selector a.active {
	background-color: var(--orange);
}

.contact-form {
	margin-top: 40px;
}

.contact-form form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
	width: 100%;
}

.contact-form .labs {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.contact-form textarea {
	resize: none;
	height: 150px;
}

.etablissement.structure .trainings-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
}

.etablissement.structure .trainings-container .training {
	padding: 0;
}

.etablissement.structure .trainings-container .training .row {
	padding: 10px;
}

.etablissement.structure .trainings-container .training .bandeau {
	width: 100%;
	height: 86px;
	border-radius: 0 0 10px 10px;
	background: linear-gradient(to right, rgba(196, 196, 196, 0.3) 0%, #5e5e5e 180%);
}

.etablissement.structure .trainings-container .training .bandeau img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0 0 10px 10px;
}
