.landing {
	overflow: hidden;
	padding-bottom: 50px;
}

.landing a {
	width: max-content;
}

#home-band {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: scroll;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	padding: 10px 0;
	background: linear-gradient(to right, var(--orange), 80%, #ffce6d);
	gap: 10px;
}

#home-band div {
	flex: 1;
	width: max-content;
	text-align: center;
	font-size: clamp(0.8rem, 1.1vw + 0.1rem, 1rem);
}

#home-band div img {
	width: clamp(20px, 8vw + 0.1rem, 40px) !important;
}

section:not(#section1) .register {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.landing section a {
	transition: 0.2s;
}

.landing section a:hover {
	scale: 1.05;
}

.landing h1,
.landing h1 span {
	font-size: clamp(2rem, 2.6vw + 0.1rem, 2.7rem);
	font-family: Inter-Bold;
}

#home-band * {
	font-family: Inter-SemiBold;
	color: white;
	fill: white;
}

#home-band div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

#home-band div img {
	width: 50px;
}

.landing-popup {
	width: 100vw;
	height: 100svh;
	overflow: scroll;
	z-index: 121;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.2);
}

.landing-popup .popup-content {
	max-width: 600px;

	background: var(--background);
	padding: 30px;
	position: relative;
	border-radius: 10px;
}

@media (max-width: 905px) {
	br {
		display: none;
	}
	.landing-popup .popup-content {
		width: 90%;
	}
	#root {
		width: 100vw;
		overflow: hidden;
	}

	#home-band {
		padding: 20px;
	}
}
