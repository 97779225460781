#section3 {
	width: 100%;
	display: flex;
	gap: 20px;
	flex-direction: column;
	margin-top: 100px;
}

#section3 ul {
	width: 90%;
}

.border {
	display: block;
	height: 78px;
	border-radius: 50px 0 0 50px;
	flex: 1;
	background-color: var(--orange);
}

#section3 ul li {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	background-color: #f6eadd;
	border-radius: 50px;
	margin: 20px 0;
	padding: 20px;
	min-height: 80px;
	list-style: none;
	padding-left: 130px;
	color: #000000 !important;
}

#section3 .mockuped {
	width: 90%;
	margin: auto;
	gap: 80px;
}

#section3 img {
	width: clamp(200px, 30%, 300px);
}

#section3 span {
	height: 100%;
	padding: 30px;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--orange);
	color: white;
	font-size: 48px;
	flex-shrink: 0;
}

@media (max-width: 905px) {
	#section3 img {
		display: none;
	}

	#section3 ul {
		width: 100%;
	}
}
