.avis {
	width: 650px;
	padding: 15px;
	margin-bottom: 20px;
}

.avis span {
	opacity: 0.6;
}

.rates {
	display: flex;
	flex-direction: column;
	gap: 7px;
}

.avis p {
	font-size: 1.1em;
	padding-bottom: 5px;
}

.avis {
	position: relative;
}

.avis .row .row2 > div > div {
	display: flex;
	align-items: center;
}

.avis .more {
	opacity: 0.6;
	margin-left: 10px;
}

.star-empty {
	background-color: white;
	border: 1px solid orange;
}

.avis img {
	display: block;
	width: 60px;
	margin-left: auto;
}

.star-ratings {
	display: flex !important;
	width: fit-content;
}
