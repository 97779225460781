.floating-menu {
	min-width: 300px;
	width: max-content;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: start !important;
	align-items: start !important;
	padding: 20px;
	top: 55px;
	right: 0px;
	background-color: var(--background);
	box-shadow:
		rgba(17, 17, 26, 0.1) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 5px;
	gap: 0 !important;
}

.floating-menu p {
	font-family: "Inter-Medium";
}

.floating-menu img {
	width: 90px !important;
	height: 90px !important;
}

.floating-menu button {
	border: 0 !important;
}

.floating-menu button {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
}

.floating-menu button:hover {
	background-color: #f8fafc;
}

.dark_mode .floating-menu button:hover {
	background-color: var(--light-blue);
}

.floating-menu button ion-icon {
	font-size: 24px;
}
