#section5 {
	width: 100%;
	margin: auto;
	margin-top: 160px;
}

#section5 h1 {
	position: relative;
	color: white;
	margin: auto;
	padding-left: 5%;
}

#section5 h1:after {
	position: absolute;
	content: "";
	z-index: -1;
	rotate: 2.74deg;
	left: -16px;
	display: block;
	width: 100%;
	height: 100%;
	padding: 40px;
	top: 50%;
	transform: translateY(-40%);
	background: linear-gradient(to right, var(--orange), 80%, #ffce6d);
}

.details-container .card p {
	font-size: clamp(1rem, 1.9vw + 0.1rem, 1.2rem);
	font-family: Inter-SemiBold;
}

.details-container .manager {
	height: 80vh;
	max-height: 600px;
	max-width: 60%;
}

.post-grid {
	padding-left: 5%;
	padding-right: 5%;
	position: relative;
}

#section5 .grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 70px 30px;
}

.post-grid a {
	position: absolute;
	bottom: -100px;
	left: 50%;
	transform: translateX(-50%);
}

@media (max-height: 805px) {
	#section5 .grid {
		margin-top: 100px;
	}
}

#section5 .grid .card {
	min-width: 200px;
}

.details-container .card svg {
	border-radius: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid var(--font-color);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.dark_mode .svg-icon * {
	fill: var(--font-color);
}

@media (max-width: 905px) {
	#section5 .manager {
		display: none;
	}

	.details-container {
		margin-top: 10vh;
	}

	#section5 h1:after {
		transform: translateY(-50%);
	}

	#section5 {
		margin-top: 80px;
	}
}
