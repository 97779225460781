.auth-page {
	display: flex;
	width: 100%;
	height: 100vh;
	min-height: 800px;
}
.auth-page .left {
	flex: 1;
}
.auth-page .left main {
	padding-left: 80px;
	padding-right: 100px;
}

.auth-page .left a {
	font-size: 1.2rem;
	font-family: "Inter-SemiBold";
}
.auth-page .form-container {
	position: relative;
	width: 40%;
	height: 100%;
	background: linear-gradient(to right, var(--orange), 80%, #ffce6d);
}

.auth-page a {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	text-decoration: none;
	color: var(--orange);
	gap: 5px;
}

.bigtitle {
	opacity: 1;
	font-size: 3rem;
}

.left.home main p {
	font-size: 1.3rem;
	font-family: "Inter-Medium";
}

.auth-page .left main {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
	padding-bottom: 50px;
}

.auth-page .form-container,
.login,
.login form {
	height: 100%;
}

.l-but {
	display: block;
	margin-left: auto;
	color: var(--font-color);
	background-color: rgba(0, 0, 0, 0);
	border: 0;
	cursor: pointer;
	border-left: 2px solid var(--orange);
	padding: 8px;
	border-radius: 0;
}

.login {
	width: 110%;
	position: absolute;
	margin: auto;
	top: 0;
	left: calc(-10% + -20px);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding-bottom: 20px;
}

.login .input-wrapper,
.login input {
	width: 100%;
}

.login form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: var(--background);
	border-radius: 0 0 20px 20px;
	box-shadow: 0px 0px 4px rgba(1, 4, 4, 0.2);
	padding: 0 4rem;
}

.auth-page .logo-container {
	width: 250px;
	position: absolute;
	top: 50px;
	left: 80px;
}

.auth-page .responsive-back {
	display: none;
}

.resume {
	font-family: "Inter-Medium";
	font-size: var(--medium-font);
}
.redirect {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	margin-top: 35px;
}
.redirect * {
	font-size: 1em;
	text-align: center;
}

.redirect a {
	text-decoration: underline;
}

@media (max-width: 1000px) {
	.logo-container {
		display: none;
	}
	.responsive-back {
		display: flex !important;
		margin-bottom: 25px;
		margin-right: auto;
		cursor: pointer;
	}

	.responsive-back i {
		color: var(--font-color);
	}

	.auth-page .left {
		display: none;
	}

	.auth-page .form-container {
		width: 100%;
	}

	.auth-page .form-container .login {
		position: static;
		width: 95%;
		margin: auto;
	}
}

@media (max-width: 480px) {
	.auth-page .form-container .login {
		width: 100%;
	}
	.login form {
		padding: 0 1rem;
	}
}
.strongpassword {
	width: 100%;
	height: 10px;
}

.strongpassword .progress {
	height: 100%;
	transition: all 0.3s;
	width: 0%;
	background-color: #d13232;
}

.progress.bad {
	width: 33%;
	background-color: #d13232;
}

.progress.ok {
	width: 63%;
	background-color: #f19829;
}

.progress.good {
	width: 100%;
	background-color: #26c318;
}

.contactform {
	margin-top: 50px;
}

.contactform form {
	margin-top: 15px;
}

.contactform textarea {
	background-color: var(--light-blue);
	resize: none;
	height: 150px;
	color: var(--text-color);
	padding: 10px;
}
