:root {
	--background: #ffffff;
	--orange: #f19829;
	--light-blue: #e5eef3;
	--darker-blue: #196d9b;
	--font-color: #000000;
	--low-lab: #534f48;
	--giant: clamp(2.5rem, 3.2vw + 0.1rem, 3.5rem);
	--title: clamp(2rem, 2.5vw + 0.1rem, 3rem);
	--medium-font: clamp(1rem, 1.25vw + 0.1rem, 1.5rem);
	--little-font: clamp(0.9rem, 0.9vw + 0.1rem, 1rem);
	--bgmenu: #f7f9fc;
	--border-color-light: rgba(0, 0, 0, 0.2); /* Couleur de bordure pour le mode clair */
	--border-color-dark: rgba(255, 255, 255, 0.5); /* Couleur de bordure pour le mode sombre */
	--border-hover-focus-light: rgba(0, 0, 0, 1); /* Couleur de bordure en hover/focus pour le mode clair */
	--border-hover-focus-dark: rgba(
		255,
		255,
		255,
		1
	); /* Couleur de bordure en hover/focus pour le mode sombre */
	--blue-electric: #3784eb;
	--tag-bg-success: #d5ead7;
	--tag-success: #73d47c;
	--tag-bg-pending: #fcd8c4;
	--tag-pending: #73d47c;
	--tag-bg-error: #f9dbd9;
	--tag-error: #fe6e6e;
	--tag-inactive: #838685;
	--tag-bg-inactive: #dadcdc;
	--tag-bg-editor: #dfebff;
}

.dark_mode {
	--background: #161616;
	--light-blue: #262626;
	--font-color: #ffffff;
	--low-lab: #ffffff;
	--bgmenu: #161616;
}

#root .app-container {
	height: 100vh;
	overflow: hidden;
}

* {
	font-weight: normal !important;
}

*::-webkit-scrollbar {
	display: block;
	width: 5px;
	height: 6px;
}

*::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	background: rgba(241, 152, 41, 0.52);
	opacity: 1;
	border-radius: 5px;
}

*::-webkit-scrollbar-corner {
	background-color: transparent;
	display: none; /* Masquer les coins */
}

/* Styles pour Firefox */
* {
	scrollbar-width: thin; /* Rendre la barre de défilement plus fine */
	scrollbar-color: rgba(241, 152, 41, 0.52) transparent; /* Couleur de la barre de défilement et du fond */
}

/* Optionnel: Pour masquer les coins dans Firefox */
*::-moz-scrollbar-corner {
	background-color: transparent;
	display: none;
}

@font-face {
	font-family: "Inter-Bold";
	src: url("../public/Inter/static/Inter-Bold.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Inter-SemiBold";
	src: url("../public/Inter/static/Inter-SemiBold.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Inter-Medium";
	src: url("../public/Inter/static/Inter-Medium.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Inter-Light";
	src: url("../public/Inter/static/Inter-Light.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Inter-Regular";
	src: url("../public/Inter/static/Inter-Regular.ttf") format("truetype");
	font-display: swap;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Inter-Medium", sans-serif;
	color: var(--font-color);
}

.graph,
.hover,
input,
textarea,
button,
.selector,
.admission,
.question,
.dashboard {
	background-color: transparent;
	padding: 10px;
	border: 1px solid var(--border-color-light); /* Utilisation de variable pour la couleur de bordure */
	border-radius: 10px;
	transition: all 0.2s;
	user-select: none;
}

.hover,
button,
.selector,
.admission {
	cursor: pointer;
}

.hover:hover,
.input:focus,
textarea:focus,
button:hover,
.selector:hover,
.admission:hover,
input:focus {
	outline: none;
	border-color: var(--border-hover-focus-light); /* Utilisation de variable pour hover/focus */
}

.dark_mode .graph,
.dark_mode .dashboard,
.dark_mode .hover,
.dark_mode .question,
.dark_mode input,
.dark_mode textarea,
.dark_mode button,
.dark_mode .selector,
.dark_mode .admission {
	border-color: var(--border-color-dark);
}

.dark_mode .hover:hover,
.dark_mode .question:hover,
.dark_mode input:focus,
.dark_mode textarea:focus,
.dark_mode button:hover,
.dark_mode .selector:hover,
.dark_mode .admission:hover {
	border-color: var(--border-hover-focus-dark); /* Changement pour dark mode en hover/focus */
}

.btntab {
	border: 0;
	text-decoration: underline;
	color: #7a8596;
	font-size: 16px;
}


body {
	background-color: var(--background);
}

h1 {
	font-family: "Inter-Bold";
	font-size: clamp(1.5rem, 2vw + 0.1rem, 2.75rem);
}

h3 {
	font-size: clamp(1.1rem, 1vw + 0.1rem, 1.9rem);
	font-family: "Inter-SemiBold";
}

a,
h4 {
	font-size: clamp(1rem, 1vw + 0.1rem, 1.2rem);
}

p,
span,
div {
	font-size: var(--little-font);
	font-family: "Inter-Regular";
}

span {
	font-family: "Inter-Medium";
}

button {
	cursor: pointer;
}

button.selected {
	background-color: var(--orange);
	color: white !important;
}

input[type="checkbox"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: var(--background);
	/* Not removed via appearance */
	border-radius: 5px !important;
	width: 10px !important;
	height: 10px !important;
	position: relative;
	cursor: pointer;
}

input[type="checkbox"]:checked {
	background-color: #505050;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 0s ease-in-out 0s;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	background-color: transparent;
}

input[type="checkbox"]:checked::after {
	content: "\2713"; /* Unicode pour la coche */
	font-size: 16px; /* Ajustez la taille selon vos besoins */
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.react-svg-wrapper,
.react-svg-wrapper > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*  header login */

.loginpage header {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 30px;
	margin-top: 20px;
}

.home-logo-container img {
	width: 60px;
}

/* TABLE MODEL */
.custom-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 5px; /* Adds vertical spacing between rows */
}

.custom-table th,
.custom-table td {
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	padding: 15px;
	text-align: left;
	border-radius: 0px;
}

.custom-table th {
	background-color: var(--light-blue);
	color: var(--font-color);
}

.custom-table tr:hover {
	background-color: #ddd;
}

.custom-table thead th {
	font-family: "Inter-Bold";
	font-size: 16px;
}

.custom-table tr td:first-child,
.custom-table tr th:first-child {
	border-left: 1px solid #ddd;
}

.custom-table tr td:last-child,
.custom-table tr th:last-child {
	border-right: 1px solid #ddd;
}

.custom-table span {
	width: fit-content;
	padding: 10px;
	color: #000000 !important;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
}

.custom-table span.inactive {
	background-color: var(--tag-bg-inactive);
}

.custom-table span:before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: var(--tag-inactive);
}

.custom-table span.success {
	margin: 0;
	font-family: Inter-Medium;
	background-color: var(--tag-bg-success);
}

.custom-table span.success:before {
	background-color: var(--tag-success);
}

.custom-table span.pending {
	background-color: var(--tag-bg-pending);
}

.custom-table span.pending:before {
	background-color: var(--tag-pending);
}

.custom-table span.error {
	background-color: var(--tag-bg-error);
}

.custom-table span.error:before {
	background-color: var(--tag-error);
}

.typeSelector {
	position: sticky;
	top: 0;
	background-color: var(--background);
	z-index: 1;
	padding: 10px 0;
}

.typeSelector h2 {
	padding-bottom: 2px;
	border-bottom: 2px solid transparent;
	cursor: pointer;
}

.typeSelector h2.selected {
	border-color: var(--orange);
}

/* TOASTS */
.Toastify__toast-container--bottom-right {
	z-index: 1000000000 !important;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--info,
.Toastify__toast {
	z-index: 1000 !important;
	border-radius: 10px !important;
	font-size: var(--little-font) !important;
	background: white !important;
	color: var(--text-color) !important;
}

.dark_mode .Toastify__toast--error,
.dark_mode .Toastify__toast--success,
.dark_mode .Toastify__toast--info,
.dark_mode .Toastify__toast {
	background: #262626 !important;
}

.toastProgress {
	background: var(--orange) !important;
}

@media (max-width: 680px) {
	.Toastify__toast {
		width: 95% !important;
		margin: auto !important;
		bottom: 80vh !important;
	}
}

/* APP PAGE  */

.app-container {
	width: 100%;
	height: 100%;
	padding-top: 20px;
	display: flex;
	flex-wrap: wrap;
	max-height: 100vh;
}

.app-page {
	flex: 1;
	max-height: 100vh;
	overflow-y: auto;
	margin-left: auto;
	display: flex;

	flex-direction: column;
	padding-bottom: 25px;
	padding-top: 45px;

	/*background: linear-gradient(77.28deg, rgba(255, 122, 122, 0.5) 2.24%, rgba(157, 77, 196, 0.5) 104.45%);
*/
	background: var(--background);
}

.app-page::-webkit-scrollbar,
body::-webkit-scrollbar {
	display: none;
}

.app-page,
body {
	-ms-overflow-style: none;
}

select {
	overflow: hidden;
	overflow: -moz-hidden-unscrollable;
	background: url("./assets//chevron.png") no-repeat right transparent;
	background-size: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
}

#appico {
	width: 69.12px;
	height: 65px;
}

.row2 {
	display: flex;
	gap: 10px;
	align-items: center;
}

.fragment_left .prof .row.homerow > div {
	display: flex;
	justify-content: flex-start;
	gap: 30px;
}

.fragment_left .prof .row.homerow > div > div:hover .pdp {
	opacity: 0.5;
}

.fragment_left .prof .row.homerow > div > div:hover ion-icon {
	transform: scale(1.2);
}

.fragment_left .prof .row.homerow > div > div {
	user-select: none;
}

.row.homerow ion-icon {
	cursor: pointer;
	position: absolute;
	right: -10px;
	top: -10px;
	color: var(--orange);
	font-size: 16px;
	background-color: var(--light-blue);
	padding: 10px;
	border-radius: 20px;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
	z-index: 10;
	transition: 0.2s;
}

/* Fragement */

.fragment {
	position: relative;
	width: 90%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
}

.fragment_left {
	width: 100%;
	z-index: 10;
	border-radius: 0 0px 20px 0;
	padding-bottom: 20px;
}

.mainfrag-container {
	width: 100%;
}

.loadingbloc img,
.loadingbloc video {
	opacity: 0;
}

.loadingbloc {
	background: linear-gradient(-45deg, #e5eef3, #aab4b9);
	background-size: 400% 400%;
	animation: gradient 2s ease-in-out infinite;
}

.dark_mode .loadingbloc,
.dark_mode .loadingbloc {
	background: linear-gradient(-45deg, #282828, #7a7575);
	background-size: 400% 400%;
	animation: gradient 2s ease-in-out infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.fragment_left .prof {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 25px;
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px 0px;
}

.stats .prof {
	flex-wrap: wrap;
	gap: 10px 0px;
}

.fragment_left .prof img {
	width: 100%;
	height: 160px;
	object-fit: cover;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.fragment_left .prof h1 {
	margin: auto 0;
	font-family: "Inter-Regular";
}

.fragment-bloc h3 {
	border-bottom: 2px solid var(--orange);
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 50px;
}

@media (max-width: 820px) {
	.static {
		display: flex;
	}

	.app-page {
		overflow: scroll;
		padding-bottom: 200px;
	}

	.player {
		flex: none !important;
	}

	.rank-card {
		max-width: 95%;
	}
}

@media (max-width: 620px) {
	.app-page {
		padding-left: 0;
	}
}

.graph {
	position: relative;
}

.error {
	color: #e13737 !important;
	margin-top: 2px;
	position: absolute;
}
table .error {
	margin: 0 !important;
	position: relative !important;
}

.error-static {
	color: #e13737;
	margin-top: 2px;
	position: relative;
}

.success {
	color: #2ecc71;
	margin: auto;
	font-family: "Inter-Bold", sans-serif;
}

.fixed-bar {
	width: 100%;
	background-color: var(--background);
	transition: box-shadow 0.3s ease-in-out;
	position: relative;
}

#ranks-row .statComponent {
	width: max-content !important;
	align-items: center;
	flex-direction: row !important;
}

#ranks-row .statComponent .databubble {
	scale: 1;
}

#ranks-row .statComponent .databubble h3 {
	font-size: 24px !important;
	margin: 0 !important;
}
