.data-container {
	display: flex;
	flex-direction: column;
	gap: 22px;
	margin-top: 20px;
}

/* Conteneur scrollable et boutons en ligne */
.data-selector {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: auto;
	width: 100%;
}


/* Style des boutons avec largeur maximale de 300px */
.data-selector button {
	max-width: 300px;
	font-size: 13px;
	font-family: "Inter-SemiBold", sans-serif;
	flex: 0 0 auto;
	padding: 10px;
	color: var(--font-color);
	border-radius: 10px;
	white-space: nowrap;
	overflow: hidden;       /* Masque le texte débordant */
	text-overflow: ellipsis; /* Ajoute des points de suspension si le texte dépasse */
}

#details .data-selector button{
	font-size: 15px;
}
/* Style optionnel pour le bouton sélectionné */
.data-selector button.selected {
	background-color: var(--orange);
}