.reponses-fragment,
.ask-fragment {
	flex: 1 0 50%;
}

.faq-container {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 50px;
	margin-top: 30px;
}

.faq-container > div {
	--n: 100%;
	width: var(--n);
	max-width: calc(var(--n) / 2);
}

.faq-container > div:nth-child(1) .colum-container {
	height: fit-content;
	max-height: 100%;
}

.faq-container > div:nth-child(2) .colum-container {
	height: fit-content;
	max-height: calc(100% - 20px);
}

@media screen and (max-width: 820px) {
	.faq-container > div {
		--n: 100%;
		max-width: var(--n);
	}
}

.faq-container > div > .row {
	margin-bottom: 25px;
}

.faq-container .row span {
	display: block;
	margin: auto 0;
	color: var(--orange);
}

.colum-container {
	width: 100%;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding-bottom: 10px;
	overflow-y: scroll;
	padding-right: 10px;
}

.question {
	position: relative;
	display: block;
	padding: 15px;
}

.question .btn-row {
	gap: 40px;
}

.question.pending button {
	border: 0;
	opacity: 0.6;
	padding: 0;
}

.question.pending > .row > .row2 > div > div {
	display: flex;
	align-items: center;
}

.question svg:not(.question.pending svg) {
	cursor: pointer;
}

.question svg:not(.question.pending svg):hover {
	fill: var(--orange) !important;
	stroke: var(--orange) !important;
}

.question.pending button:not(.disabled):hover {
	color: var(--orange);
}

.question textarea {
	width: 100%;
	height: 150px;
	margin-top: 5px;
	resize: none;
}

.question input {
	outline: none;
	width: 100%;
	height: fit-content;
}

.questions-container,
.rep-container {
	max-height: 600px;
	overflow: auto;
	padding-right: 10px;
}

ion-icon[name="checkmark-circle-outline"] {
	color: #2ecc71;
}

@media (max-width: 820px) {
	.faq-container {
		flex-direction: column;
	}
}

#fullpage {
	height: calc(100% - 70px - 100px);
}
