.column.graph h4 {
	width: 100%;
	font-size: 14px;
	color: #505057;
	padding: 10px;
	text-align: left;
	font-family: "Inter-Bold", sans-serif;
}

.column.graph .row {
	border-bottom: 2px solid #ced4dd;
	padding-bottom: 5px;
	margin-bottom: 10px;
}

.column.graph.locked-graph > div {
	height: 100% !important;
}

.column.graph.locked-graph p {
	width: 80%;
	text-align: left;
}

.column.graph .row:nth-child(1) {
	margin-bottom: 15px;
	position: sticky;
	top: 0;
	background-color: var(--background);
}

.column.graph .row:nth-child(1) * {
	font-family: Inter-SemiBold;
}

.column.graph {
	height: 550px;
	min-width: 200px;
}

.column.graph .btn {
	width: fit-content;
	margin-left: auto;
	font-family: "Inter-Bold", sans-serif;
	color: var(--orange);
	cursor: pointer;
	margin-top: 20px;
}

.column.graph ion-icon {
	color: var(--orange);
}
