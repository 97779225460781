#section6 {
	width: 100%;
	margin-top: 150px;
	position: relative;
}

#section6 > div {
	width: 100%;
	margin: auto;
	padding-left: 5%;
}

#section6 h4 {
	margin-top: 50px;
}

.grid2x2 {
	width: 95%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
	margin-top: 50px;
}

.grid2x2 > div {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

	padding: 30px;
	border-radius: 10px;
	height: 170px;
	text-align: center;
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0.21);
	background-color: var(--background);
}

.grid2x2 > div p {
	font-size: clamp(1rem, 2vw + 0.1rem, 1.4rem);
}

.grid2x2 > div span {
	position: absolute;
	font-size: clamp(2rem, 3.2vw + 0.1rem, 2.8rem);
	font-family: Inter-Bold;
	width: 70px;
	height: 70px;
	color: white;
	background-color: var(--orange);
	border-radius: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.grid2x2 > div:nth-child(1) span,
.grid2x2 > div:nth-child(3) span {
	top: -20px;
	right: -10px;
}

.grid2x2 > div:nth-child(2) span,
.grid2x2 > div:nth-child(4) span {
	top: -20px;
	left: -10px;
}

#section6 a {
	width: fit-content;
	display: block;
	margin: auto;
	margin-top: 30px;
}

@media (max-width: 905px) {
	.grid2x2 > div {
		padding: 10px;
		height: 150px;
	}

	.grid2x2 > div span {
		width: 50px;
		height: 50px;
	}

	#section6 h4 {
		margin-top: 10px;
	}

	.grid2x2 {
		margin-top: 40px;
	}
}

@media (max-width: 545px) {
	.grid2x2 {
		grid-template-columns: 1fr;
		gap: 30px;
	}

	.grid2x2 > div {
		padding: 10px;
		height: 100px;
	}
}
