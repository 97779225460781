#section9 {
	width: 100%;
	text-align: center;
	margin-top: 100px;
}

#section9 .row2 {
	overflow-x: auto;
	justify-content: space-evenly;
	margin-top: 30px;
	padding: 0px 20px;
}
