.locked-content {
	width: 100%;
	height: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	gap: 20px;
}

.locked-content p {
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: fit-content;
	margin: 30px 0 10px auto;
	font-family: "Inter-Bold", sans-serif;
	color: var(--orange);
	cursor: pointer;
}

@keyframes blink {
	50% {
		color: var(--orange);
	}
	100% {
		color: initial;
	}
}

.blink a {
	animation: blink 0.2s ease-in-out 3; /* Adjust timing and repeat count as necessary */
}
