.landing header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 5px 20px;
	display: flex;
	opacity: 0;
	z-index: -1;
	justify-content: space-between;
	transition: 0.2s;
	background-color: var(--background);
	box-shadow:
		rgba(17, 17, 26, 0.1) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 5px;
}

.landing header.display {
	opacity: 1;
	z-index: 10;
}

.landing header * {
	font-size: 0.8rem;
}

@media (max-width: 905px) {
	.landing header img {
		display: none;
	}

	.landing header .row2 {
		margin-left: auto;
	}
}

@media (max-width: 505px) {
	.landing header .toggle-switch {
		display: none;
	}
}
