.footer {
	width: 100%;
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	margin-top: 100px;
}

.footer-img {
	width: clamp(300px, 30%, 500px);
}

.menu {
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	width: 60%;
	margin-top: 50px;
}

.menu-item {
	width: auto;
	list-style: none;
	text-align: center;
	margin: auto;
}

.item {
	text-decoration: none;
	color: var(--font-color);
	font-size: clamp(1rem, 1.1vw + 0.1rem, 2em);
	font-weight: 600;
}

.item:hover {
	color: var(--orange);
}

.list-icon {
	margin: auto;
	display: flex;
	justify-content: center;
	margin-top: 40px;
}

.icon-item {
	width: auto;
	list-style: none;
	text-align: center;
	margin: 20px;
}

.icon {
	font-size: 0;
}

.ion-icon {
	width: 40px;
	margin: 0 auto;
	display: inline-block;
	transition: 0.3s;
}

.ion-icon:hover {
	-ms-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.dwn-img {
	position: relative;
	width: 100%;
	margin-top: -6%;

	z-index: 1;
}

.menu-p {
	margin: 0 auto 1% auto;
}

.privacy-policy {
	position: relative;
	margin: 0 0 3vh 0;
	color: var(--font-color);

	text-decoration: none;
	z-index: 2; /* pour garantir la superposition image et lien cliquable*/
}

.privacy-policy:hover {
	color: var(--orange);
}

@media (max-width: 800px) {
	.icon:hover {
		color: var(--orange);
		transform: none;
	}

	.ion-icon {
		margin: 0 7px;
		display: inline-block;
		transition: 0.3s;
	}

	.dwn-img {
		margin-top: 30px;
	}

	.list-icon {
		margin: 20px 0;
	}
}
