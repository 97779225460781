#section7 {
	margin-top: 150px;
	position: relative;
}

#section7:after {
	display: block;
	content: "";
	width: 110%;
	height: 100%;
	top: 0;
	position: absolute;
	background: linear-gradient(to right, var(--orange), 80%, #ffce6d);
	z-index: -1;

	right: -20px;
	rotate: -3.72deg;
}

#section7 h1 {
	color: white;
	line-height: 90%;
	padding-top: 100px;
	text-align: center;
	font-size: clamp(3rem, 5vw + 0.1rem, 7rem);
}

#section7 h4,
#section7 p {
	color: white;
}

#section7 p {
	text-align: center;
	transform: scale(0.8);
	padding-top: 20px;
}

#section7 form {
	width: 700px;
	margin: auto;
	margin-top: 40px;
	padding-bottom: 100px;
}

#section7 form div button {
	position: absolute;
	right: 0;
	height: 100%;
	width: 80px;
	background-color: var(--orange);
	color: white;
	font-size: 24px;
	border: 0;
}

#section7 input {
	border: 0;
	width: 100%;
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.71);
}

@media (max-width: 905px) {
	#section7 form {
		width: 90%;
		margin: auto;
		margin-top: 50px;
	}
}
