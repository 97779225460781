.couverture {
	display: flex;
	width: 100%;
	height: 120px;
	position: relative;
	background-color: var(--light-blue);
}

.couverture img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.couverture label {
	position: absolute;
	right: 15px;
	bottom: 15px;
	background-color: var(--light-blue);
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
}
