.carousel {
	width: calc(100% - 72px);
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 11;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: rgba(29, 36, 36, 0.6);
}

.carousel.side {
	width: calc(100% - 240px);
}

.carousel-content {
	display: flex;
	flex-direction: row;
	position: relative;
	z-index: 1;
}

.carousel button {
	width: 80px;
	height: 80px;
	border-radius: 50px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	background-color: var(--background);
}

.carousel button:first-child {
	left: 15%;
}

.carousel button:last-child {
	right: 15%;
}

.carousel .publication .img-container {
	width: 40vw;
	height: 40vw;
	max-width: 70vh;
	max-height: 70vh;
}
