.icon-with-text {
	display: flex;
	align-items: center;
	gap: 5px; /* Espace entre l'icône et le texte */
	background-color: var(--tag-bg-editor);

	padding: 5px 10px;
	border-radius: 5px;
	color: white;
}

.icon-with-text.editor {
	background-color: var(--tag-bg-pending);
}

.icon-with-text span {
	font-size: 0.7rem;
}

.icon-with-text div {
	display: flex;
	align-items: center;
	justify-content: center;
}
