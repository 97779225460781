.pricing-table {
	width: 100%;
	border-collapse: collapse;
	--color-bd: rgba(0, 0, 0, 0.1);
	--filled-bd: rgba(0, 0, 0, 1);
	position: relative;
}

.dark_mode .pricing-table {
	--color-bd: rgba(255, 255, 255, 0.1);
	--filled-bd: rgba(255, 255, 255, 1);
}

.pricing-table th,
.pricing-table td {
	border: 1px solid var(--color-bd);
	padding: 10px;
	text-align: left;
}

.pricing-table thead th {
	position: sticky;
	top: 0;
	background-color: var(--background); /* Ensure text does not blend with content behind the header */
	z-index: 10; /* Ensure the header is above other content, adjust if necessary */
}

.center {
	text-align: center !important;
}

.pricing-table tbody td:first-child:not(.section-header td),
.pricing-table thead tr th:first-child {
	background-color: #f8fafb;
}

.dark_mode .pricing-table tbody td:first-child:not(.section-header td),
.dark_mode .pricing-table thead tr th:first-child {
	background-color: #1a1a1a;
}

.pricing-table .section-header td:first-child {
	font-size: 16px;
}

.pricing-table .section-header td,
.pricing-table thead tr th:first-child {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: "Inter-Bold";
}

.pricing-table thead tr th:first-child {
	font-size: 20px;
}

.pricing-table thead tr th:not(:first-child) {
	text-align: center !important;
}

.pricing-table .checked {
	font-size: 30px;
}

.pricing-table .center:not(.checked),
.pricing-table thead tr th span {
	opacity: 0.6;
}

.pricing-table tr td:nth-child(2).checked {
	color: #05bd90;
}

.pricing-table tr td:nth-child(3).checked {
	color: #ffc22a;
}

.pricing-table tr td:nth-child(4).checked {
	color: #107fb1;
}

.pricing-table h3 {
	font-size: 20px;
}

#standard h3 {
	color: #7a8596;
}

#gold h3 {
	color: #ffd262;
}

#custom h3 {
	color: #107fb1;
}

.pricing-table td {
	vertical-align: middle; /* Alignez verticalement le contenu des td */
}

.pricing-table button {
	width: 165px;
	font-size: 14px;
	margin-top: 40px;
}

#standard button {
	border-color: #05bd90;
}

#gold button {
	background: linear-gradient(to right, #ffc22a, #ffd878);
}

#custom button {
	background: linear-gradient(to right, #107fb1 13%, #6dc6ee);
}

thead span {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pricing-table tr td:nth-child(2),
.pricing-table tr th:nth-child(2) {
	border-left: 2px solid var(--filled-bd); /* Couleur plus foncée pour la bordure gauche */
	border-right: 2px solid var(--filled-bd); /* Couleur plus foncée pour la bordure droite */
}

.pricing-table tr th:nth-child(2) {
	border-top: 2px solid var(--filled-bd); /* Couleur plus foncée pour la bordure supérieure */
}

.pricing-table tr:last-child td:nth-child(2) {
	border-bottom: 2px solid var(--filled-bd); /* Couleur plus foncée pour la bordure inférieure */
}
