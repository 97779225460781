.publications {
	max-width: 100%;
	overflow: scroll;
	display: flex;
	gap: 10px;
	align-items: center;
	padding-bottom: 10px;
}

.publications label div {
	display: flex;
	justify-content: center;
}

.labelplus div {
	display: flex;
	justify-content: center;
}

.labelplus {
	cursor: pointer;
}
