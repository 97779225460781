#section4 {
	width: 80%;
	margin: auto;
	margin-top: 30px;
	padding: 50px 0;
	position: relative;
	justify-content: center;
	align-items: center;
	text-align: center;
}

#section4 .row2 {
	width: 100%;
	margin: auto;
	flex-direction: row;
	justify-content: center;
	position: relative;
	margin-top: 25px;
	overflow-x: auto;
	flex-wrap: nowrap;
	gap: 10px;
	padding: 10px;
}

#section4 .card img {
	width: 100%;
	max-width: 250px;
	padding: 30px;
	object-fit: contain;
}

#section4 a {
	z-index: 1;
	position: absolute;
	bottom: -60px;
	left: -22px;
	box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 905px) {
	#section4 #mockup img {
		display: none;
	}

	#section4 {
		position: relative;
	}

	#section4 #mockup {
		margin-top: 0px;
		position: relative;
	}

	#section4 a {
		position: static;
		display: block;
		margin: auto;
	}

	#section4 h1 {
		margin-bottom: 10px;
	}

	#section4 .row2 {
		flex-wrap: wrap-reverse;
	}

	#section4 .card {
		width: 200px;
		height: 100px;
	}
}
